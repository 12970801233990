import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../styles/Tabs.css'
import LoadingLine from '../atoms/LoadingLine';
import { authState } from './FormularioLog';
import { dewaltServicesGetAll } from '../../api/DewaltServices';
import Swal from 'sweetalert2'

let numberAllServices = 0
let numberNewServices = 0
let numberNotVerifiedServices = 0
let numberInRepairServices = 0
let numberRepairServices = 0
let numberNoProcessServices = 0
let numberFinishedServices = 0

const TabMenuConstants={
    all:'TODAS',
    new:'NUEVAS',
    inrev:'EN REVISIÓN',
    inrep:'EN REPARACIÓN',
    rep:'REPARADO',
    norep:'ENTREGA SIN REPARACIÓN',
    delivered:'ENTREGADO'
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab2(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab3(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab4(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab5(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab6(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
export default function NavTabs(props) {
    const [loading, setLoading] = useState(false)
    const [allServices1, setAllServices] = useState(5)
    const authServiceCenterName = localStorage.getItem('UserLogged');

    const alertWrong = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong with the list!',
        })
    }
    
    useEffect(()=>{
        fetchData()
    }, [])

    const fetchData = async () =>{
        setLoading(true)
        try{
            const response2 = dewaltServicesGetAll();
            const data = (await response2).data;
            //TODAS
            var filteredListAll = data.result.items.filter(e =>{
                return (`${e.service_center.social_reason}`
                    .toLowerCase()
                    .includes(authServiceCenterName.toLowerCase())        
                );
            });

            //NUEVAS
            var filteredListTabsByStatusNew = filteredListAll.filter((e)=>{
                const arrayLength = e.status.length
                const statusItem = e.status[arrayLength - 1]
                return statusItem.status.toLowerCase().includes('new')
                // 
            })

            //EN REVISION
            var filteredListTabsByStatusNotVerified = filteredListAll.filter((e)=>{
                const arrayLength = e.status.length
                const statusItem = e.status[arrayLength - 1]
                return statusItem.status.toLowerCase().includes('not verified')
                // 
            })

            //EN REPARACION
            var filteredListTabsByStatusInRepair = filteredListAll.filter((e)=>{
                const arrayLength = e.status.length
                const statusItem = e.status[arrayLength - 1]
                return statusItem.status.toLowerCase().includes('in repair')
                // 
            })

            //REPARADO
            var filteredListTabsByStatusRepair = filteredListAll.filter((e)=>{
                const arrayLength = e.status.length
                const statusItem = e.status[arrayLength - 1]
                return statusItem.status.toLowerCase().includes('repair')
                // 
            })

            //ENTREGA SIN REPARACION
            var filteredListTabsByStatusNoProcess = filteredListAll.filter((e)=>{
                const arrayLength = e.status.length
                const statusItem = e.status[arrayLength - 1]
                return statusItem.status.toLowerCase().includes('no process')
                // 
            })

            //ENTREGADO
            var filteredListTabsByStatusNoProcess = filteredListAll.filter((e)=>{
                const arrayLength = e.status.length
                const statusItem = e.status[arrayLength - 1]
                return statusItem.status.toLowerCase().includes('finished')
                // 
            })

            numberAllServices = `(${filteredListAll.length})`
            numberNewServices = `(${filteredListTabsByStatusNew.length})`
            numberNotVerifiedServices = `(${filteredListTabsByStatusNotVerified.length})`
            numberInRepairServices = `(${filteredListTabsByStatusInRepair.length})`
            numberRepairServices = `(${filteredListTabsByStatusRepair.length})`
            numberNoProcessServices = `(${filteredListTabsByStatusNoProcess.length})`
            numberFinishedServices = `(${filteredListTabsByStatusNoProcess.length})`

            if(numberAllServices>10){
                numberAllServices = '+10'
            }
            if(numberNewServices>10){
                numberNewServices = '+10'
            }
            if(numberNotVerifiedServices>10){
                numberNotVerifiedServices='+10'
            }
            if(numberInRepairServices>10){
                numberInRepairServices='+10'
            }
            if(numberRepairServices>10){
                numberRepairServices='+10'
            }
            if(numberNoProcessServices>10){
                numberNoProcessServices='+10'
            }
            if(numberFinishedServices>10){
                numberFinishedServices='+10'
            }



        }catch(error){
            console.log("error get List")
            alertWrong()
            console.log(error.response)
        }finally{
            setLoading(false)
        }
    }

        const [value, setValue] = React.useState(0);
    
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
        return (
        <div className="Tabs-stl" >
            <AppBar position="static"
            style={{justifyContent: 'space-evenly'}}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{background:"#FEBD18",color:"#000"}}
                    
                >
                    <LinkTab label={`${TabMenuConstants.all}${numberAllServices}`} tabAction={props.tabAll}  className="tabs-only-stl" style={{width:'10px'}} />
                    <LinkTab label={`${TabMenuConstants.new}${numberNewServices}`} tabAction={props.tabAction} className="tabs-only-stl" />
                    <LinkTab2 label={`${TabMenuConstants.inrev}${numberNotVerifiedServices}`} tabAction={props.tabAction2} className="tabs-only-stl"  />
                    <LinkTab3 label={`${TabMenuConstants.inrep}${numberInRepairServices}`} tabAction={props.tabAction3} className="tabs-only-stl"   />
                    <LinkTab4 label={`${TabMenuConstants.rep}${numberRepairServices}`} tabAction={props.tabAction4} className="tabs-only-stl"  />
                    <LinkTab5 label={`${TabMenuConstants.norep}${numberNoProcessServices}`}  tabAction={props.tabAction5} className="tabs-only-stl"   />
                    <LinkTab6 label={`${TabMenuConstants.delivered}${numberFinishedServices}`}  tabAction={props.tabAction6} className="tabs-only-stl" />
                </Tabs>
            </AppBar>
        </div>
        );
}

