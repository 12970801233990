import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css'

import '../styles/headerProduct.css'
const useStyles = makeStyles({
    root: {
        background: '#FEBD18',
        border: 0,
        borderRadius: 3,
        color: '#000',
        height: 48,
        padding: '20px 5px',
        fontFamily: 'IBM Plex Sans Condensed',
        fontWeigth: 'bold',
        letterSpacing: '1.25px',
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'left',
    },
    
});

export default function Hook(props) {
    const classes = useStyles();
    return <div className={classes.root}>
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <h3 className="box-stl1"><strong>{props.label}</strong></h3>
                    </div>
                    <div className="col-8">
                        <div className="texto-right">
                            <a href={props.invoice} target="_blank" download style={{textDecoration:'none'}}>
                                <h3 className="txt-descarga-stl">
                                    {props.label2}
                                </h3>
                            </a>
                        </div>
                    </div>   
                </div>
            </div>
            
        </div>
    </div>;
        
}