import React from 'react';
import '../styles/login.css'


function InputFormLog (props){
    return(
        <div>
            <form action ="">
                <input className="input-stl"
                    type= {props.type} 
                    name = {props.name} 
                    placeholder = {props.placeholder}
                    id = {props.id}
                    style = {props.style}
                    onChange = {props.change}
                    value = {props.value}
                    >
                    
                </input>
            </form>     
        </div>
    );
}
export default InputFormLog;