import React from 'react';
import '../styles/login.css'

import LogoDW from '../../Images/DEWALT_Logo_Footer.png'
function HeaderMain (){
    return(
        <div className="footer-stl2">
            <img className="footer-logo" src={LogoDW} alt="header Fondo + logo"></img>
        </div>
    );
}

export default HeaderMain;