
import {axios as http} from "../frameworks_and_drivers/Axios"

import { BASE_URL, TOKEN } from "../config/settings.json";

const opts = {
    headers:{
        Authorization: `Bearer ${TOKEN}`
    }

}

export const dewaltServicesGetAll = async () => {
    const URL=`${BASE_URL}/v1/dewalt-services/get-all`;
    const response = await http.get(URL,opts);
    return response;
}
export const getDewaltServicesById = async (objectId) => {

    const URL=`${BASE_URL}/v1/dewalt-services/get-by-id/${objectId}`;
    const response = await http.get(URL,opts);
    return response;
}

export const getDewaltProductById = async (objectId) => {
    const URL=`${BASE_URL}/v1/products/get-by-id/${objectId}`;
    const response = await http.get(URL,opts);
    return response;
}

export const getDewaltCategoryById = async (objectId) => {
    const URL=`${BASE_URL}/v1/categories/get-by-id/${objectId}`;
    const response = await http.get(URL,opts);
    return response;
}
export const getDewaltSubCategoryById = async (objectId) => {
    const URL=`${BASE_URL}/v1/subcategories/get-by-id/${objectId}`;
    const response = await http.get(URL,opts);
    return response;
}
export const dewaltServicesChangeStatus = async (objectId) => {
    const URL=`${BASE_URL}/v1/dewalt-services/change-status/${objectId}`;
    const response = await http.put(URL,{},opts);
    return response;
}

export const dewaltServicesChangeStatusPending = async (objectId) => {
    const data = {status: "pending" }
    const URL=`${BASE_URL}/v1/dewalt-services/change-status/${objectId}`;
    const response = await http.put(URL,data,opts);
    return response;
}

export const dewaltServicesVerifyWarranty = async (warranty,paid,objectId) => {
    const data = {has_warranty: warranty ,user_paid: paid};
    const URL=`${BASE_URL}/v1/dewalt-services/verify-warranty/${objectId}`;
    const response = await http.put(URL,data,opts);
    return response;
}
export const LoginServiceCenter = async (email,password) => {
    const data = {email: email ,password: password};
    const URL=`${BASE_URL}/v1/service-centers/login`;
    const response = await http.post(URL,data,opts);
    return response;
}


export const DHLService = async (guideNumber) => {
    const URL=`${BASE_URL}/v1/dewalt-services/get-by-tracking/${guideNumber}`;
    const response = await http.post(URL,opts);
    return response;
}
