import React from 'react';
import {Link} from 'react-router-dom'
import Arrow from '../../Images/Arrow.png'
import '../styles/ColumnOrder.css'

function ArrowButton (props){
    return (
        <Link to={props.link}>
            <div className="arrowPos">
                <button className="Noborde">
                    <img src={Arrow} alt='img'></img>
                </button> 
            </div>
        </Link>
        
    );
}
export default ArrowButton