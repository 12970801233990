import React from 'react';
import '../../constants/Global.css'
import NavMenu from '../atoms/Navmenu'

import LogoDW from '../../Images/DEWALT_Logo.png'
function HeaderLogin (){
    return(
                <div className="mainHeader">
                    <img className="logoMainHeader" src={LogoDW} alt="Logo DEWALT" ></img> 
                </div>
    );
}

export default HeaderLogin;