import React, {useState, useEffect}from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom'
//COMPONENTS
import CustomButton from '../atoms/CustomButton';
import InputFormLog from '../atoms/inputFormLog';
import {LoginServiceCenter} from '../../api/DewaltServices'
import Swal from 'sweetalert2'
import LoadingOver from '../atoms/LoadingOver';
import AuthStateContext from '../../context/AuthContext';
//API
import AuthService from "../../api/AuthService";

// CSS
import '../styles/login.css'
import '../../constants/Global.css'

var AuthState = '';

export default function FormLog (props){
    let history = useHistory();
    const [openLogin, setOpenLogin] = useState(false);
    const [logged, setLogged] = useState(()=>{
        try{
            const loggedIn = window.localStorage.getItem('loggedIn')
            return loggedIn;
        }catch(e){
            return false
        }
    });
    const [userDate, setUserDate] = useState({
        usuario: '',
        password: ''
    })
    const [loginServiceCenter, setLoginServiceCenter] = useState(()=>{
        try{
            const Auth = window.localStorage.getItem('UserLogged')
            return Auth;
        }catch(e){
            return false
        }
    });

    const [LinkLog, setLinkLog] = useState('')
    const [loading, setLoading] = useState(false)
    
    const handleChange= (event)=>{
            setUserDate({
                ...userDate,
                [event.target.name] : event.target.value
            })
        
    }

    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error: Usuario o contraseña equivocados',
        })
    }

    //Se guarda la variable del login
    const setLocalStorage = value =>{
        try{
            window.localStorage.setItem('loggedIn', value)
            setLogged(value)
        }catch(e){
            console.log(e)
        }
    }
    const setLocalData= value =>{
        try{
            window.localStorage.setItem('UserLogged', value)
            setLoginServiceCenter(value)
        }catch(e){
            console.log(e)
        }
    }

    const loginService = async (usuario,password) =>{

        setOpenLogin(!openLogin)
        try{
            LoginServiceCenter(usuario,password)
            const response = LoginServiceCenter(usuario,password);
            const data = (await response).data;
            setLocalData(`${data.result.social_reason}`)
            if (data){
                // Auth = true;
                setLocalStorage(true);

                <AuthStateContext.Provider value={true}>
                    {history.push('/Orders')}
                </AuthStateContext.Provider>
                
                // setLinkLog("/Orders")
                //return <Redirect to={"/Orders"} />
                
            }
            return(data)
        }catch(error){
            // Auth = false;
            setLocalStorage(false);

            console.log("error Login")
            alertWrongGet()
            setLinkLog("/")
            
            console.log(error.response)
        }finally{

            setOpenLogin(false)
        }   
        // console.log(response)
    }   

        return(
            <div>
                    <h2 className="h2-stl">{props.label}</h2>
                    <div className="EmptySpaceSmall"></div>
                    <div>
                        <InputFormLog 
                            type        = "text"
                            name        = "usuario"
                            id          = "Usuario_LoginPage"
                            placeholder = "Usuario"
                            change      = {handleChange}
                            style       =  {{fontSize: "25px"}}
    
                        />
                        <div className="EmptySpaceSmall" ></div>
                        <InputFormLog   
                            type        = "password"
                            name        = "password"
                            id          = "pass_LoginPage"
                            placeholder = "Contraseña"
                            change      = {handleChange}
                            style       =  {{fontSize: "25px"}}
                        />  
                    </div>
                    <div className="EmptySpaceSmall" ></div>
                        <CustomButton 
                        
                                variant     =   "outlined" 
                                background  =   "#C4C4C4" 
                                color       =   "#FFFFFF"
                                width       =   "356px"
                                heigth      =   "57px"
                                fontSize    =   "24px"
                                label       =   "INICIAR SESIÓN"
                                link        =   {LinkLog}
                                // link        =   "/Orders"
                                action      =   {()=>{loginService(userDate.usuario,userDate.password)}}        //ver funcion
                        />
                    <div className="py-2"></div>
                        <div className="row">
                            <div className = "col-5"></div>
                    
                            <div className = "col-5"></div>
                        </div>
                        <LoadingOver
                            open = {openLogin}
                        />      
            </div>
        );
    
}


export function authState (){
    return AuthState;
}

