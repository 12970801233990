import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'


// COMPONENTS
import HeaderMain from '../Components/moleculas/HeaderMain'
import FooterLog from '../Components/moleculas/FooterLog'
import ProductTemplate from '../Components/template/ProductTemplate';
import { authState } from '../Components/moleculas/FormularioLog';
// CSS

import '../constants/Global.css'


function OrdenesPage(props){
    const authServiceCenterName = localStorage.getItem('UserLogged');
    return (
        <div>
            <HeaderMain 
            serviceCenter = {authServiceCenterName}/>
            <ProductTemplate  orderId = {props.orderId}/>
            <div className="img-footer">
                <FooterLog />
            </div>
        </div>
    );
}   

export default OrdenesPage;