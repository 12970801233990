import React from 'react';
import '../../constants/Global.css'
import NavMenu from '../atoms/Navmenu'
import 'bootstrap/dist/css/bootstrap.css'

import '../atoms/styles/NavMenu.css'

import LogoDW from '../../Images/DEWALT_Logo.png'
function HeaderMain (props){
    return(
                <div className="mainHeader">
                    <div className="row">
                        <div className="col-12 col-md-2">
                            <img className="logoMainHeader" src={LogoDW} alt="Logo DEWALT" ></img>
                        </div>
                        <div className="col-12 col-md-3">
                            <h5 className="text-sc pt-4">{props.serviceCenter}</h5>
                        </div>
                        <div className="col-12 col-md-7 pt-3">
                            <NavMenu/>
                        </div >
                    </div>
                </div>
    );
}

export default HeaderMain;