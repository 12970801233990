import React from 'react';

// COMPONENTS
import ArrowButton from '../atoms/arrowbutton';

//CSS
import '../styles/ColumnOrder.css'
import '../../constants/Global.css'

function ColumnOrders (props){

    return (
        <div className="columnBorder">    
            <div className="OrdenesText">
                <h3 className="h3-stl">ORDENES</h3>
            </div>
            <div className="arrowPos">
            <ArrowButton
                link = {props.link}
            />
            </div>
        </div>
        
    );

}

export default ColumnOrders
// style={{borderRight: "2px solid #000", minHeight:"90vh" ,display:"flex",flexDirection:"row"}}