import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import {Link, Redirect} from 'react-router-dom'


// COMPONENTS
import HeaderMain from '../Components/moleculas/HeaderMain'
import FooterLog from '../Components/moleculas/FooterPag'
import ColumnOrders from '../Components/moleculas/ColumnOrdenes'


import { authState } from '../Components/moleculas/FormularioLog';

// CSS
import '../constants/Global.css'

function OrdenesPage(){
  
    // const authServiceCenterName = authState();
    const authServiceCenterName = localStorage.getItem('UserLogged');
    // const loginServiceCenter = localStorage.getItem('UserLogged');
    const [logged, setLogged] = useState(()=>{
        try{
            const loggedIn = window.localStorage.getItem('loggedIn')
            return loggedIn;
        }catch(e){
            return false
        }
    });
    // if(loginServiceCenter.result){
    //     console.log('name',loginServiceCenter.result.social_reason)
    // }
    // console.log('objetoObtenido: ', loginServiceCenter);
    
    return (
        <div>
            {logged == 'false' && <Redirect to="/"></Redirect>}
            <HeaderMain 
            serviceCenter = {authServiceCenterName}/>
            <div class="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-2" >
                        <div>
                            <ColumnOrders 
                                link    =   "/Orders/List"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="img-footer">
                <FooterLog />
            </div>
        </div>
    );
}   

export default OrdenesPage;