import React,{useEffect}from 'react';


// COMPONENTS
import HeaderDewalt from '../Components/moleculas/HeaderDewalt'
import FooterLog from '../Components/moleculas/FooterPag'
import FormularioLog from '../Components/moleculas/FormularioLog';
import HeaderLogin from '../Components/moleculas/HeaderLogin';


// CSS
import '../constants/Global.css'
import './Styles/Login.css'

function LoginPage (){
        return (
            <div>
                <HeaderLogin />
                <div className="Center">
                    <HeaderDewalt />
                </div>
                <div className="EmptySpaceMedium"></div>
                <div className="Center my-5">
                    <FormularioLog 
                        label =  "INGRESA A TU PLATAFORMA DE GESTIÓN"
                    />
                </div>
                <div className="img-footer">
                    <div className ="pady-5"></div>
                    <div className ="pady-4"></div>
                    <div className ="py-1"></div>
                    <FooterLog />
                </div>
            </div>
        );
}   

export default LoginPage;