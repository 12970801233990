import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom'


function CustomButton (props){
    return (
        <Link to={props.link} style={{textDecoration:'none'}}> 
            <Button 
                variant= {props.variant}               //contained     outlined
                style = {{
                    color: props.color, 
                    background:props.background, 
                    width:props.width, 
                    height:props.height, 
                    fontSize:props.fontSize,            
                }
                }
                onClick = {props.action}
                >
                {props.label}       
            </Button>
    
            
        </Link>
        
    );
}
export default CustomButton