import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'


import OrderListTemplate from '../Components/template/OrderListComponent'
import HeaderMain from '../Components/moleculas/HeaderMain';
import FooterLog from '../Components/moleculas/FooterLog'
import ColumnOrders from '../Components/moleculas/ColumnOrdenes';
// CSS
import '../constants/Global.css'

import { authState } from '../Components/moleculas/FormularioLog';



function OrdenesPageList (){
    const authServiceCenterName = localStorage.getItem('UserLogged');
        return(
            <div>
                <HeaderMain 
                serviceCenter = {authServiceCenterName}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-2" >
                            <div>
                                <ColumnOrders />
                            </div>
                        </div>
                        <div className="col-12 col-md-10">
                            <OrderListTemplate />
                            <div className="EmptySpaceSmall"></div>
                        </div>
                    </div>
                </div>
                <div className="img-footer">
                    <FooterLog />
                </div>
            </div>
            
    );
}   

export default OrdenesPageList;