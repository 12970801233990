import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

import HeaderboxProduct from '../atoms/HeaderboxProduct'

import '../styles/headerProduct.css'
import '../../constants/Global.css'

// function base64ToBlob(base64) {
//     const binaryString = window.atob(base64);
//     const len = binaryString.length;
//     const bytes = new Uint8Array(len);
//     for (let i = 0; i < len; ++i) {
//         bytes[i] = binaryString.charCodeAt(i);
//     }
//     var blob = new Blob([bytes], { type: 'application/pdf' });
//     var url = URL.createObjectURL(blob);
//     return(url)
// };

export default function ProductBox (props){
    const status = props.state;
    const image = props.image;
    const invoice = props.invoice;
    // var urlInvoice = base64ToBlob(invoice)
    const renderBoxWarranty = () =>{
        return(
            <div>
                <div className="EmptySpaceSmall2"></div>
                <div className="Box-yellow">
                    <h4 className="h4-stl-1-black"><strong>PRODUCTO DENTRO<br/>DE GARANTÍA</strong></h4>
                </div>
            </div>
            
        );
    }
    const BootstrapInput = withStyles((theme) => ({
        root: {
            'label + &': {
            marginTop: theme.spacing(3),
            },
        },
        input: {
            position: 'relative',
            backgroundColor: '#0abf53',
            border: '1px solid #ced4da',
            fontSize: 13,
            lineHeight:'119%',
            textAlign: 'center',
            padding: '10px 20px 10px 20px',
            marginLeft:'1px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.      
            fontFamily: [
                'IBM Plex Sans Condensed',
                'Roboto',
                'Arial',
                'sans-serif',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#000000',
                boxShadow: '0 0 0 0.2rem #FEBD18',
            },
        },
    }))(InputBase);
    const BootstrapInput2 = withStyles((theme) => ({
        root: {
            'label + &': {
            marginTop: theme.spacing(3),
            },
        },
        input: {
            position: 'relative',
            backgroundColor: '#ed1c24',
            border: '1px solid #ced4da',
            fontSize: 13,
            lineHeight:'119%',
            textAlign: 'center',
            padding: '10px 20px 10px 20px',
            marginLeft:'1px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.      
            fontFamily: [
                'IBM Plex Sans Condensed',
                'Roboto',
                'Arial',
                'sans-serif',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#000000',
                boxShadow: '0 0 0 0.2rem #FEBD18',
            },
        },
    }))(InputBase);
    const renderMenuSelect = () =>{
        return(
        <div>
            <FormControl >
            <InputLabel id="demo-customized-select-label"></InputLabel>
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={props.warrantyStatus}
                    onChange={props.handleChange}
                    input={status == 'unchecked' ? <BootstrapInput/> : <BootstrapInput2/>  }
                >
                    <MenuItem value={'unchecked'} style={{background: '#0abf53'}}><strong>PRODUCTO DENTRO<br/>DE GARANTÍA</strong></MenuItem>
                    <MenuItem value={'pending'} style={{background: '#ed1c24'}}><strong>PRODUCTO FUERA<br/> DE GARANTÍA</strong></MenuItem>
                </Select>
            </FormControl>
        </div>
        );
    } 
    return(
        <div className="row box-stl">
            <HeaderboxProduct 
            label = 'PRODUCTO'
            label2 = 'Descargar factura'
            invoice = {invoice}
            />
            <div>
                <div className="EmptySpace-1"> </div>
                <div className="container">
                    <div className="row">
                        <div className ="col-12 col-md-5">
                            <div className="box-product">
                                <img src={image} alt="Product" className="imgProduct"></img>
                            </div>
                        </div>
                        <div className = "col-12 col-md-7 marg-pro1">
                            <h4 className="h4-stl2"><strong>Categoría:</strong> {props.categories}</h4>
                            <h4 className="h4-stl2"><strong>Subcategoría:</strong> {props.subCategories}</h4>
                            <h4 className="h4-stl2"><strong>SKU:</strong> {props.product}</h4>
                            <h4 className="h4-stl2"><strong>Motivo de falla: </strong> {props.failureReason}</h4>
                        </div>
                    </div>
                    {status === 'unchecked' && renderMenuSelect()}
                    {status === 'pending' && renderMenuSelect()}
                    {status === 'in repair' && renderBoxWarranty()} 
                    {status === 'repaired' && renderBoxWarranty()} 
                    {status === 'finished' && renderBoxWarranty()} 
                    <div className="row EmptySpace-2">

                    </div>
                    
                </div>
            </div>
            
        </div>

    );
}