import React, {useState ,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import HeaderboxProduct from '../atoms/HeaderboxProduct'
import SendButtom from '../atoms/SendButton'

import '../styles/headerProduct.css'
import '../../constants/Global.css'
import {DHLService} from '../../api/DewaltServices'
import Swal from 'sweetalert2'
// function base64ToBlob(base64) {
//     const binaryString = window.atob(base64);
//     const len = binaryString.length;
//     const bytes = new Uint8Array(len);
//     for (let i = 0; i < len; ++i) {
//         bytes[i] = binaryString.charCodeAt(i);
//     }
//     var blob = new Blob([bytes], { type: 'application/pdf' });
//     var url = URL.createObjectURL(blob);
//     return(url)
// };

export default function ClientBox (props){
    const [DHL, setDHL] = useState({result:{
        url:''
    }})
    const stateCompra = props.state;

    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong with DHL!',
        })
    }
    // useEffect(async()=>{
    //         const dewaltDHL= await getDHL();
    // }, [])
    // const getDHL = async () =>{
            
    //     try{
    //         const response = DHLService(props.DHL.ShipmentIdentificationNumber);  
    //         const data = (await response).data;
    //         setDHL(data)
    //         return(data)
    //     }catch(error){
    //         console.log("error get dhl")
    //         console.log(error.response)
    //         alertWrongGet() 
    //     }finally{
    //     }   
    // }
    // console.log('dhl',DHL)

    const renderBoxSendState= () =>{
        const guide = props.base64.LabelImage[0].GraphicImage.url.publicPath;
        // var urlGuide = base64ToBlob(guide)
        
        return(
            
            <div className="pad-client">
                
                <h3 className="h3-stl-product" style={{marginBottom:'20px'}}><strong>ESTADO DEL ENVÍO DE REGRESO</strong></h3>
                <h3 className="h3-stl-product">Numero de guía DHL: #{props.DHL.ShipmentIdentificationNumber}</h3>
                <div className="EmptySpaceSmall3"></div>
                <a href={guide} target='_blank' download style={{textDecoration:'none'}}>
                    <SendButtom
                        label = 'DESCARGAR GUIA'
                    />
                </a>
                    
    
                <div className="EmptySpaceSmall3"></div>
                <a href="https://www.dhl.com/mx-es/home.html" target='_blank' download style={{textDecoration:'none'}}>
                    <SendButtom
                        label = 'CONSULTAR EL ESTADO DEL ENVÍO'
                    />
                </a>
            </div>
            
        );
    }
    const renderBoxSendStateFinished= () =>{
        return(
            <div className="pad-client">
                <h3 className="h3-stl-product" style={{marginBottom:'20px'}}><strong>ESTADO DEL ENVÍO DE REGRESO</strong></h3>
                <h3 className="h3-stl-product">Numero de guía DHL: #687621938</h3>
            </div>
            
        );
    }
    var pickUpValidation = false;
    var pickUpValidationEndBox = false;
    if(stateCompra === 'repaired'){
        if(props.TipeOfRequest === false){
            pickUpValidation = true;
        }else{
            pickUpValidation = false;
        }
    }
    if(stateCompra === 'no process'){
        if(props.TipeOfRequest === false){
            pickUpValidation = true;
        }else{
            pickUpValidation = false;
        }
    }
    if(stateCompra == 'finished'){
        if(props.TipeOfRequest == false){
            pickUpValidationEndBox = true
        }else{pickUpValidationEndBox = false}
    }
    var PlaceOrigin = ''
    var PlaceReturn = ''
    if(props.shippingPlaceOrigin === 'home'){
        PlaceOrigin = 'Domicilio'
    }
    if(props.shippingPlaceOrigin === 'dhl'){
        PlaceOrigin = 'Sucursal'
    }
    if(props.shippingPlaceOrigin === 'service center'){
        PlaceOrigin = 'Centro de servicio'
    }
    if(props.shippingPlaceReturn === 'home'){
        PlaceReturn = 'Domicilio'
    }
    if(props.shippingPlaceReturn === 'dhl'){
        PlaceReturn = 'Sucursal'
    }
    if(props.shippingPlaceReturn === 'service center'){
        PlaceReturn = 'Centro de servicio'
    }
    
    return(
        <div className="row box-stl">
            
            <HeaderboxProduct 
            label = 'CLIENTE'
            label2 = ''
            />
            
            <div className="pad-client">
                <h3 className="h3-stl-product" style={{marginBottom:'20px'}}><strong>INFORMACIÓN DEL CONTACTO</strong></h3>
                <h3 className="h3-stl-product">{props.clientName}</h3>
                <h3 className="h3-stl-product">{props.clientEmail}</h3>
                <h3 className="h3-stl-product">{props.clientPhone}</h3>
            </div>
            <div className="pad-client">
                <h3 className="h3-stl-product" style={{marginBottom:'20px'}}><strong>DIRECCIÓN DE ENVÍO DE ORIGEN</strong></h3>
                <h3 className="h3-stl-product"><strong>{PlaceOrigin}</strong></h3>
                <h3 className="h3-stl-product">Dirección: {props.originShipment}</h3>
                <h3 className="h3-stl-product">{props.originCity}</h3>
                <h3 className="h3-stl-product">{props.originState}</h3>
            </div>
            <div className="pad-client">
                <h3 className="h3-stl-product" style={{marginBottom:'20px'}}><strong>DIRECCIÓN DE ENVÍO DE REGRESO</strong></h3>
                <h3 className="h3-stl-product"><strong>{PlaceReturn}</strong></h3>
                <h3 className="h3-stl-product">Dirección: {props.returnShipment}</h3>
                <h3 className="h3-stl-product">{props.returnCity}</h3>
                <h3 className="h3-stl-product">{props.returnState}</h3>
            </div>  
                {/* {pickUpValidation === true && renderBoxSendState()} */}
                {pickUpValidation === true && renderBoxSendState()}
                {/* {pickUpValidationEndBox === true && renderBoxSendStateFinished()} */}
                {pickUpValidationEndBox === true && renderBoxSendStateFinished()}


        </div>

    );
}