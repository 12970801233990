import React from 'react';
import '../styles/login.css'

import banner from '../../Images/banner.jpeg'
function HeaderMain (){
    return(
        <div>
            <img className="imgLogoSE" src={banner} alt="header Fondo + logo"></img>
        </div>
    );
}

export default HeaderMain;