import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../constants/Global.css";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import TableHead from "@material-ui/core/TableHead";
import LoadingLine from "../atoms/LoadingLine";
import Swal from "sweetalert2";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { authState } from "./FormularioLog";

import { dewaltServicesGetAll } from "../../api/DewaltServices";
import Ingarrow from "../../Images/Arrow-left.png";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes?.number.isRequired,
  onPageChange: PropTypes?.func.isRequired,
  page: PropTypes?.number.isRequired,
  rowsPerPage: PropTypes?.number.isRequired,
};

function TableOrders(props) {
  const [loading, setLoading] = useState(false);
  const authServiceCenterName = localStorage.getItem("UserLogged");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const query = props.query; //Para traer el filtro del input en Order List component
  const tabvalue = props.tabvalue;
  const authStateLogin = authState();
  const [stateData, setStateData] = useState({
    result: {
      items: [
        {
          id: "",
          order: "",
          user: [
            {
              name: "",
              phone: "",
            },
          ],
          status: [
            {
              status: "",
              created_at: "2021-08-09T12:03:50.000Z",
            },
          ],
          service_center: {
            state: "",
            social_reason: "",
          },
        },
      ],
    },
  });
  const alertWrong = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong with the list!",
    });
  };
  //API CALLS
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response2 = dewaltServicesGetAll();
      const data = (await response2).data;
      setStateData(data);
    } catch (error) {
      console.log("error get List");
      alertWrong();
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  const filteredByStateList = stateData?.result?.items?.filter((e) => {
    return `${e.service_center.social_reason}`
      .toLowerCase()
      .includes(authServiceCenterName.toLowerCase());
  });
  //Filtrado de lista
  const filteredList = filteredByStateList?.filter((e) => {
    return `${e.order} ${e.user.name} ${
      e.status[e.status.length - 1].created_at
    }`
      .toLowerCase()
      .includes(query.toLowerCase());
  });

  //FILTRO PARA LOS TABS
  var filteredListTabsByStatus = filteredList?.filter((e) => {
    const arrayLength = e.status.length;
    const statusItem = e.status[arrayLength - 1];
    return statusItem.status.toLowerCase().includes(tabvalue.toLowerCase());
    //
  });
  filteredListTabsByStatus.reverse();

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, stateData.length - page * rowsPerPage);
  //SET PAGE
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (tabvalue == "all") {
    filteredListTabsByStatus = filteredList;
    filteredListTabsByStatus.reverse();
  }

  return (
    <div className="container pad3 gris2">
      <div className="martop">
        <TableContainer component={Paper} style={{ background: "#EBEBEB" }}>
          <Table aria-label="custom pagination table">
            <TableHead style={{ background: "#FFFFFF" }}>
              <TableRow>
                <TableCell>
                  {" "}
                  <h3 className="h3-stl">ORDEN</h3>{" "}
                </TableCell>
                <TableCell>
                  <h3 className="h3-stl">FECHA</h3>
                </TableCell>
                <TableCell align="left">
                  <h3 className="h3-stl">CLIENTE</h3>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredListTabsByStatus?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredListTabsByStatus
              )?.map((row) => (
                <TableRow key={row.id} style={{ border: "1px solid #C4C4C4" }}>
                  <TableCell
                    align="left"
                    style={{ borderBottom: "1px solid #C4C4C4" }}
                  >
                    <b># {row?.order}</b>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ borderBottom: "1px solid #C4C4C4" }}
                  >
                    {
                      //  {row.status[row.status.length -1].created_at.substr(5,2) === '01' &&()}
                      row?.status[row.status.length - 1].created_at?.substr(
                        8,
                        2
                      ) +
                        " - " + //dia
                        row?.status[row.status.length - 1].created_at?.substr(
                          5,
                          2
                        ) +
                        " a las " + //mes
                        row?.status[row.status.length - 1].created_at?.substr(
                          11,
                          2
                        ) +
                        row?.status[row.status.length - 1].created_at?.substr(
                          13,
                          3
                        ) +
                        ""
                    }
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ borderBottom: "1px solid #C4C4C4" }}
                  >
                    {row?.user?.name}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ borderBottom: "1px solid #C4C4C4" }}
                  >
                    <Link to={`/Orders/Product/${row.id}`}>
                      <img src={Ingarrow} alt=">"></img>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]} //Si se quieren poner paginas {[5, 10]}
                  colSpan={4}
                  count={filteredListTabsByStatus.length} //antes se ponia state.data
                  rowsPerPage={rowsPerPage}
                  page={page}
                  // onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  // ActionsComponent={TablePaginationActions}
                  labelRowsPerPage=""
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {loading && <LoadingLine />}
      </div>
    </div>
  );
}

export default TableOrders;
