import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { Nav} from 'react-bootstrap';
import './styles/NavMenu.css'


export default function Hook(props) {
    const [visible, setVisible] = useState(false);

    return(
    <div >
        <Nav className="justify-content-end" activeKey="/home">
            <Nav.Item>
                <Nav.Link href="/" className="text-nav-stl mx-3" onClick={(auth)=>{
                try{
                    return window.localStorage.setItem('loggedIn',false)
                }catch(e){
                    return false
                }
            }}>Cerrar sesión</Nav.Link>
            </Nav.Item>

        </Nav>
        
    </div>
    );
}