import LoginPage from "./Pages/Login";
import OrdenesPage from "./Pages/Ordenes";
import OrdenesPageList from "./Pages/Ordeneslist";
import ProductPage from "./Pages/ProductPage";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LoginServiceCenter } from "./api/DewaltServices";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/Orders" component={OrdenesPage} />
        <Route exact path="/Orders/List" component={OrdenesPageList} />
        {/* <Route exact path="/Orders/Product" component={ProductPage} /> */}
        <Route
          exact
          path="/Orders/Product/:id"
          render={(routerProps) => (
            <ProductPage orderId={routerProps.match.params.id} />
          )}
        />
      </Switch>
    </BrowserRouter>
    // <div>
    //   <header>
    //     <LoginPage />
    //   </header>
    // </div>
  );
}

export default App;
