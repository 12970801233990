import React, {useState}  from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import {Link, Redirect} from 'react-router-dom'


// COMPONENTS

import TabMenu from '../moleculas/TabMenu';
import InputFormLog from '../atoms/inputFormLog';
import TableOrders from '../moleculas/Table';


// CSS
import '../../constants/Global.css'

//IMGS
import Yarrow from '../../Images/Yellowarrow.png'
function OrdenesPageList (props){
    const [query,  setQuery] = React.useState('')

    //TABS
    const [valuetab, setValuetab] = React.useState('');
    const [valuetab2, setValuetab2] = React.useState('');
    const [valuetab3, setValuetab3] = React.useState('');
    const [numberNew, setNumberNew] = React.useState(0);
    const [logged, setLogged] = useState(()=>{
        try{
            const loggedIn = window.localStorage.getItem('loggedIn')
            return loggedIn;
        }catch(e){
            return false
        }
    });
   
        return(
            <div>   
                {/* {authValue === false && <Redirect to="/"></Redirect>} */}
                {logged == 'false' && <Redirect to="/"></Redirect>}
                <div>
                    <div className="elementsrow">
                        <Link to ="/Orders">
                            <img src={Yarrow} className="iconwidth" style={{marginTop:"2em"}} alt="<"></img>
                        </Link>
                                
                        <h3 className="h3-stl gris pad1">ORDENES</h3>
                    </div>
                    <div className="row tab-stl">
                        <TabMenu 
                            value = {valuetab}
                            tabAll={() =>{setValuetab('all')}}
                            tabAction={() =>{setValuetab('new')
                                        setValuetab2('')
                                        setValuetab3('')}}
                            tabAction2={() =>{setValuetab('not verified')
                                    setValuetab2('unchecked')
                                    setValuetab3('pending')}}
                            tabAction3={() =>{setValuetab('in repair')
                                    setValuetab2('')
                                    setValuetab3('')}}
                            tabAction4={() =>{setValuetab('repair')
                                    setValuetab2('')
                                    setValuetab3('')}}
                            tabAction5={() =>{setValuetab('no process')
                                    setValuetab2('')
                                    setValuetab3('')}}
                            tabAction6={() =>{setValuetab('finished')
                                    setValuetab2('')
                                    setValuetab3('')}}
                        />
                        <div className="EmptySpaceSmall"> </div>
                        <div style={{textAlign:'left'}}>
                            <InputFormLog 
                                Type = "text"
                                name = "filtro"
                                placeholder = "Filtrar ordenes"
                                id  ="filtro_ordenes"
                                style = {{fontSize: "14px", background:'#EBEBEB',width:'30vh'}}
                                value = {query}
                                change = {(e) =>{setQuery(e.target.value)}}
                            />
                        </div>
                        {/* TABLA */}
                        <div className="row" style={{width:'96%'}}>
                            <TableOrders 
                            query = {query}
                            tabvalue = {valuetab}
                            valuetab2 = {valuetab2}
                            valuetab3 = {valuetab3}
                            />
                        </div>
                        
                    </div>
                </div> 
            </div>
    );
    
}   
export default OrdenesPageList;

