import React from 'react';
import Button from '@material-ui/core/Button';
import '../styles/productButton.css'

export default function ClientButtom(props) {

    return <Button className="boton-Second"
        onClick = {props.action}
        style = {{marginRight:'1em',background: '#FEBD18'}}
        ><h3 className="h3-stl-button2">{props.label}</h3>
    </Button>;
}