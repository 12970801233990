import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

// COMPONENTS
import ColumnOrdersProducts from "../moleculas/ColumOrdersProducts";
import ProductButtom from "../atoms/ProductoButtom";
import ProductBox from "../moleculas/ProductBox";
import ClientBox from "../moleculas/ClientBox";
import Loading from "../atoms/Loading";
import LoadingOver from "../atoms/LoadingOver";
import {
  getDewaltServicesById,
  dewaltServicesChangeStatus,
  dewaltServicesVerifyWarranty,
  dewaltServicesChangeStatusPending,
  getDewaltProductById,
  getDewaltSubCategoryById,
  getDewaltCategoryById,
} from "../../api/DewaltServices";
import Swal from "sweetalert2";
// CSS
import "../../constants/Global.css";
//IMGS
import Yarrow from "../../Images/Yellowarrow.png";

const ProductTemplateConstants = {
  warning:
    "En el caso que el cliente quiera modificar algún dato, deberá comunicarse via:",
  mail: "  Correo:",
  mailadress: "support@dewalt.com.mx",
  phone: "  Telefono:",
  phonenumber: "018008472312",
};
// const stateCompra = 'Nuevo'  //En revisión
export default function ProductTemplate(props) {
  const [status, setStatus] = useState("new");
  const [openLogin, setOpenLogin] = useState(false);
  const [productImage, setProductImage] = useState("");
  const [logged, setLogged] = useState(() => {
    try {
      const loggedIn = window.localStorage.getItem("loggedIn");
      return loggedIn;
    } catch (e) {
      return false;
    }
  });

  const [stateDataProduct, setStateDataProduct] = useState({
    result: {
      id: "",
      product: {
        id: "",
        category: "",
        subcategory: "",
        product_code: "",
        image: {
          url: {
            publicPath: "",
          },
        },
        url: "",
      },
      failure_reason: "",
      invoice: {
        url: {
          publicPath: "",
        },
      },
      user: [
        {
          name: "",
          phone: "",
        },
      ],
      origin_shipment: [
        {
          address: "",
          state: "",
          city: "",
          shipment_request: {
            ShipmentIdentificationNumber: "1236110606",
          },
          shipping_place: "",
        },
      ],
      return_shipment: {
        pickup: "",
        address: "",
        state: "",
        city: "",
        shipping_place: "",
        shipment_request: {
          LabelImage: [
            {
              GraphicImage: {
                publicPath: "",
              },
            },
          ],
        },
        shipping_place: "",
      },
      status: [
        {
          status: "new",
          created_at: "",
        },
      ],
      order: "",
      ticket: [
        {
          id: "",
        },
      ],
    },
  });
  const [product, setProduct] = useState({
    result: {
      category_id: "",
      subcategory_id: "",
      product_code: "",
      images: "",
    },
  });
  const [category, setCategory] = useState({
    result: {
      name: "",
    },
  });
  const [subCategory, setSubCategory] = useState({
    result: {
      name: "",
    },
  });

  var TipeOfRequest = false;
  if (stateDataProduct.result.return_shipment.pickup) {
    TipeOfRequest = stateDataProduct.result.return_shipment.pickup;
  }

  //Alerts
  const alertWrongGet = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong with the status!",
    });
  };
  const alertWrongPut = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong in sent!",
    });
  };
  const alertWrongPut2 = (message) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: `${message}`,
    });
  };
  // LLAMADAS A LAS APIS

  useEffect(async () => {
    const dewaltService = await getDataProducts();
    // await getDataProducts();
    // console.log('use',stateDataProduct)

    const idProduct = dewaltService.result.product.id;

    if (idProduct) {
      // const productArray = await getProductById(idProduct)
      // setProduct(productArray)
      const categoryId = dewaltService.result.product.category;
      const subcategoryId = dewaltService.result.product.subcategory;

      if (categoryId) {
        const categoryArray = await getCategoryById(categoryId);
        setCategory(categoryArray);
      } else {
        console.log("no se pudo obtener la categoria");
      }
      if (subcategoryId) {
        const subcategoryArray = await getSubCategoryById(subcategoryId);
        setSubCategory(subcategoryArray);
      } else {
        console.log("no se pudo obtener la subcategoria");
      }
      if (stateDataProduct.result.product.url) {
        setProductImage(stateDataProduct.result.product.url);
      } else if (stateDataProduct.result.product.image.url.publicPath) {
        setProductImage(stateDataProduct.result.product.image.url.publicPath);
      } else {
        setProductImage("");
      }
    } else {
      console.log("no se pudo obtener el id del producto");
    }
  }, []);

  // useEffect(
  //     () => {
  //         async function GetAlls() {
  //             await getDataProducts()
  //             // const get2 = getProductById()
  //             // const get3 =await getCategoryById()
  //             // const get =await getSubCategoryById()
  //         }
  //         GetAlls();

  // }, [])

  const getDataProducts = async () => {
    setOpenLogin(!openLogin);
    try {
      const response = getDewaltServicesById(props.orderId);
      const data = (await response).data;
      setStateDataProduct(data);

      return data;
    } catch (error) {
      console.log("error get data order");
      console.log(error.response);
      alertWrongGet();
    } finally {
      setOpenLogin(false);
    }
  };
  const changeStatusOrder = async () => {
    setOpenLogin(!openLogin);
    try {
      await dewaltServicesChangeStatus(props.orderId);
      await getDataProducts();
    } catch (error) {
      console.log("error while changin status order");
      alertWrongPut2("Error al cambiar el estado de la orden");
      console.log(error.response);
    } finally {
      setOpenLogin(false);
    }
  };

  const changeStatusOrderPending = async () => {
    setOpenLogin(!openLogin);
    try {
      await dewaltServicesChangeStatusPending(props.orderId);
      await getDataProducts();
    } catch (error) {
      console.log("error while changin status order");
      alertWrongPut();
      console.log(error.response);
    } finally {
      setOpenLogin(false);
    }
  };

  const verifyWarrantyPut = async (warranty, paid) => {
    setOpenLogin(!openLogin);
    try {
      // const changeWarranty = await dewaltServicesVerifyWarranty(props.orderId)
      await dewaltServicesVerifyWarranty(warranty, paid, props.orderId);
      await getDataProducts();
    } catch (error) {
      console.log("error while changin Warranty status order");
      alertWrongPut();
      console.log(error);
    } finally {
      setOpenLogin(false);
    }
  };

  // const getProductById = async (ProductId) =>{
  //     setLoading(true)
  //     try{
  //         const response = getDewaltProductById(ProductId);
  //         const data = (await response).data;
  //         // setProduct(data)
  //         return(data)
  //     }catch(error){
  //         console.log("error get data product order")
  //         console.log(error.response)
  //         alertWrongGet()
  //     }finally{
  //         setLoading(false)
  //     }
  // }

  const getCategoryById = async (category_id) => {
    setOpenLogin(!openLogin);
    try {
      const response = getDewaltCategoryById(category_id);
      const data = (await response).data;
      // setCategory(data)
      return data;
    } catch (error) {
      console.log("error get data order");
      console.log(error.response);
      alertWrongGet();
    } finally {
      setOpenLogin(false);
    }
  };

  const getSubCategoryById = async (subcategory_id) => {
    setOpenLogin(!openLogin);
    try {
      const response = getDewaltSubCategoryById(subcategory_id);
      const data = (await response).data;
      // setSubCategory(data)
      return data;
    } catch (error) {
      console.log("error get data order");
      console.log(error.response);
      alertWrongGet();
    } finally {
      setOpenLogin(false);
    }
  };

  const userData = stateDataProduct.result.user;
  const userOriginShipment = stateDataProduct.result.origin_shipment;
  const userReturnShipment = stateDataProduct.result.return_shipment;

  // const [has_warranty, setHas_warranty] = useState('false')

  //Encontrar el estatus final de la persona
  const arrayLength = stateDataProduct.result.status.length;
  const statusItem = stateDataProduct.result.status[arrayLength - 1].status;

  //Renders de botones
  const renderBotonStateNew = () => {
    return (
      <div>
        <ProductButtom
          style={{ marginLeft: "1em", background: "#FEBD18" }}
          label="PASAR A REVISIÓN"
          action={() => changeStatusOrder(props.orderId)}
        />
      </div>
    );
  };

  const renderBotonStateNoVerified = () => {
    return (
      <div style={{ display: "flex", alignContent: "rigth" }}>
        <ProductButtom
          style={{ marginLeft: "1em", background: "#0abf53" }}
          label="PRODUCTO DENTRO DE GARANTÍA"
          action={() => {
            verifyWarrantyPut(true, false, props.orderId);
          }}
          // verifyWarrantyPut(props.orderId)
        />
        <ProductButtom
          label="PRODUCTO FUERA DE GARANTÍA"
          style={{ marginLeft: "1em", background: "#ed1c24" }}
          action={() => {
            changeStatusOrderPending(props.orderId);
          }}
        />
        {/* ()=>{verifyWarrantyPut(false,false,props.orderId)} */}
      </div>
    );
  };

  const renderBotonState3Garantia = () => {
    return (
      <div>
        <ProductButtom
          style={{ marginLeft: "1em", background: "#FEBD18" }}
          label="PASAR A REPARACIÓN"
          action={() => changeStatusOrder(props.orderId)}
        />
      </div>
    );
  };

  const renderBotonState3SinGarantia = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ProductButtom
          style={{ marginLeft: "1em", background: "#FEBD18" }}
          label="ENTREGA SIN REPARACIÓN"
          action={() => {
            verifyWarrantyPut(false, false, props.orderId);
          }}
        />
        <ProductButtom
          style={{ marginLeft: "1em", background: "#FEBD18" }}
          label="PASAR A REPARACIÓN"
          action={() => {
            verifyWarrantyPut(false, true, props.orderId);
          }}
        />
      </div>
    );
  };

  const renderBotonStateInRepair = () => {
    return (
      <div>
        <ProductButtom
          style={{ marginLeft: "1em", background: "#FEBD18" }}
          label="PASAR A REPARADO"
          action={() => changeStatusOrder(props.orderId)}
        />
      </div>
    );
  };

  const renderBotonStateRepaired = () => {
    return (
      <div>
        <ProductButtom
          style={{ marginLeft: "1em", background: "#FEBD18" }}
          label="ENTREGADO"
          action={() => changeStatusOrder(props.orderId)}
        />
      </div>
    );
  };
  const renderBotonStateDelivered = () => {
    return <div></div>;
  };
  //Menu deslegable
  const handleChangeSelect = (event) => {
    setStatus(event.target.value);
    if (statusItem == "unchecked") {
      changeStatusOrderPending(props.orderId);
    }
    if (statusItem == "pending") {
      verifyWarrantyPut(true, false, props.orderId);
      // verifyWarrantyPut(true,false,props.orderId)
    }
  };
  function renderWarranty(props) {
    if (statusItem === "unchecked") {
      return renderBotonState3Garantia();
    }
    if (statusItem === "pending") {
      return renderBotonState3SinGarantia();
    }
  }

  var statusLabel = "";
  if (statusItem === "new") {
    statusLabel = "Nuevo";
  }
  if (statusItem === "not verified") {
    statusLabel = "En revisión";
  }
  if (statusItem === "unchecked") {
    statusLabel = "En revisión";
  }
  if (statusItem === "in repair") {
    statusLabel = "En reparación";
  }
  if (statusItem === "repaired") {
    statusLabel = "Reparado";
  }
  if (statusItem === "finished") {
    statusLabel = "Entregado";
  }
  if (statusItem === "no process") {
    statusLabel = "Entrega sin reparación";
  }
  if (statusItem === "pending") {
    statusLabel = "En revisión";
  }
  // if(stateDataProduct.result.product.url !=){
  //     setProductImage(stateDataProduct.result.product.url)
  // }
  var imgURL = "";
  if (stateDataProduct.result.product.image.url) {
    imgURL = stateDataProduct.result.product.image.url.publicPath;
  } else {
    imgURL = stateDataProduct.result.product.url;
  }

  const MadeDate = () => {
    const year =
      stateDataProduct.result.status[stateDataProduct.result.status.length - 1]
        .created_at;
    const mounth = stateDataProduct.result.status[
      stateDataProduct.result.status.length - 1
    ].created_at.substr(5, 2);
    const day = stateDataProduct.result.status[
      stateDataProduct.result.status.length - 1
    ].created_at.substr(8, 2);
    const hour = stateDataProduct.result.status[
      stateDataProduct.result.status.length - 1
    ].created_at.substr(11, 2);
    const minute = stateDataProduct.result.status[
      stateDataProduct.result.status.length - 1
    ].created_at.substr(13, 3);
    var mounthString = "";
    var hourString = "";
    var zone = "";
    if (mounth === "01") {
      mounthString = "Enero";
    }
    if (mounth === "02") {
      mounthString = "Febrero";
    }
    if (mounth === "03") {
      mounthString = "Marzo";
    }
    if (mounth === "04") {
      mounthString = "Abril";
    }
    if (mounth === "05") {
      mounthString = "Mayo";
    }
    if (mounth === "06") {
      mounthString = "Junio";
    }
    if (mounth === "07") {
      mounthString = "Julio";
    }
    if (mounth === "08") {
      mounthString = "Agosto";
    }
    if (mounth === "09") {
      mounthString = "Septiembre";
    }
    if (mounth === "10") {
      mounthString = "Octubre";
    }
    if (mounth === "10") {
      mounthString = "Noviembre";
    }
    if (mounth === "12") {
      mounthString = "Diciembre";
    }
    //hour
    if (hour === "01") {
      hourString = "01";
      zone = "am";
    }
    if (hour === "02") {
      hourString = "02";
      zone = "am";
    }
    if (hour === "03") {
      hourString = "03";
      zone = "am";
    }
    if (hour === "04") {
      hourString = "04";
      zone = "am";
    }
    if (hour === "05") {
      hourString = "05";
      zone = "am";
    }
    if (hour === "06") {
      hourString = "06";
      zone = "am";
    }
    if (hour === "07") {
      hourString = "07";
      zone = "am";
    }
    if (hour === "08") {
      hourString = "08";
      zone = "am";
    }
    if (hour === "09") {
      hourString = "09";
      zone = "am";
    }
    if (hour === "10") {
      hourString = "10";
      zone = "am";
    }
    if (hour === "11") {
      hourString = "11";
      zone = "am";
    }
    if (hour === "12") {
      hourString = "12";
      zone = "m";
    }
    if (hour === "13") {
      hourString = "01";
      zone = "pm";
    }
    if (hour === "14") {
      hourString = "02";
      zone = "pm";
    }
    if (hour === "15") {
      hourString = "03";
      zone = "pm";
    }
    if (hour === "16") {
      hourString = "04";
      zone = "pm";
    }
    if (hour === "17") {
      hourString = "05";
      zone = "pm";
    }
    if (hour === "18") {
      hourString = "06";
      zone = "pm";
    }
    if (hour === "19") {
      hourString = "07";
      zone = "pm";
    }
    if (hour === "20") {
      hourString = "08";
      zone = "pm";
    }
    if (hour === "21") {
      hourString = "09";
      zone = "pm";
    }
    if (hour === "22") {
      hourString = "10";
      zone = "pm";
    }
    if (hour === "23") {
      hourString = "11";
      zone = "pm";
    }
    if (hour === "24") {
      hourString = "12";
      zone = "m";
    }

    const date =
      mounthString + " " + day + " a las " + hourString + minute + zone;
    return date;
  };

  {
    !stateDataProduct && <Loading />;
  }
  {
    !product && <Loading />;
  }
  {
    !category && <Loading />;
  }
  {
    !subCategory && <Loading />;
  }
  // const Base64 = stateDataProduct.result.return_shipment.shipment_steps.shipment_request.LabelImage[0].GraphicImage
  return (
    <div class="container-fluid">
      {logged == "false" && <Redirect to="/"></Redirect>}
      <div className="row">
        <div className="col-12 col-lg-2">
          <div>
            <ColumnOrdersProducts link="/Orders/List" />
          </div>
        </div>
        <div className="col-12 col-lg-10">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-12 col-md-4">
              <div style={{ display: "flex" }}>
                <Link to="/Orders/List">
                  <img
                    src={Yarrow}
                    className="iconwidth ArrowButtonProduct"
                    alt="#"
                  ></img>
                </Link>
                <h3 className="h3-stl gris pad2">
                  # {stateDataProduct.result.order}
                </h3>
                <h4 className="h4-stl-bold mar1 bg-gris">{statusLabel}</h4>
                {/* se debe poner el estado que tiene */}
              </div>
              <h4 className="h4-stl-1">
                Ticket zendesk # {stateDataProduct.result.ticket.id}
              </h4>
              <h4 className="h4-stl-1">{MadeDate()}</h4>
            </div>
            <div className="col-0 col-1">
              <div style={{ marginTop: "2em", marginLeft: "2em" }}></div>
            </div>
            <div
              className="col-12 col-md-4"
              style={{ marginTop: "3em", textAlign: "right", padding: "0" }}
            >
              {statusItem === "new" && renderBotonStateNew()}
              {/* estado en revision */}
              {statusItem === "not verified" && renderBotonStateNoVerified()}
              {statusItem === "unchecked" && renderWarranty(statusItem)}

              {/* {stateCompra === 'En revisión ' && renderBotonState3Garantia()} */}
              {statusItem === "pending" && renderWarranty(statusItem)}
              {/* estado en reparacion*/}
              {statusItem === "in repair" && renderBotonStateInRepair()}
              {statusItem === "repaired" && renderBotonStateRepaired()}
              {statusItem === "finished" && renderBotonStateDelivered()}
              {statusItem === "no process" && renderBotonStateRepaired()}

              <div className="EmptySpaceSmall"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-12 col-md-4">
              <ProductBox
                state={statusItem}
                handleChange={handleChangeSelect}
                categories={category.result.name}
                subCategories={subCategory.result.name}
                warrantyStatus={statusItem}
                failureReason={stateDataProduct.result.failure_reason}
                product={stateDataProduct.result.product.product_code}
                image={imgURL} //if else
                // image = { stateDataProduct.result.product.image === {} ? stateDataProduct.result.product.url : stateDataProduct.result.product.image.url.publicPath}
                invoice={stateDataProduct.result.invoice.url.publicPath}
              />
            </div>
            <div className="col-0 col-1"></div>
            <div className="col-12 col-md-4">
              <ClientBox
                state={statusItem}
                clientName={userData.name}
                clientEmail={userData.email}
                clientPhone={userData.phone}
                originShipment={userOriginShipment.address}
                originCity={userOriginShipment.city}
                originState={userOriginShipment.state}
                returnShipment={userReturnShipment.address}
                returnCity={userReturnShipment.city}
                returnState={userReturnShipment.state}
                base64={
                  stateDataProduct.result.return_shipment.shipment_request
                }
                DHL={stateDataProduct.result.return_shipment.shipment_request}
                TipeOfRequest={TipeOfRequest}
                shippingPlaceOrigin={
                  stateDataProduct.result.origin_shipment.shipping_place
                }
                shippingPlaceReturn={
                  stateDataProduct.result.return_shipment.shipping_place
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2">
              <div className="EmptySpaceSmall3"></div>
            </div>

            <div className="col-12 col-md-8 my-5" style={{ paddingTop: 20 }}>
              <div className="changeClientDataWarning">
                <h3 className="h3-stl-product" style={{ marginTop: "20px" }}>
                  <span className="blankwarningLogo">&#9888;</span>
                  <strong>{ProductTemplateConstants.warning}</strong>
                </h3>
                <h3 className="h3-stl-product">
                  <span className="warningLogo">&#9888;</span>
                  <strong>{ProductTemplateConstants.mail}</strong>
                  {ProductTemplateConstants.mailadress}
                </h3>
                <h3 className="h3-stl-product" style={{ marginBottom: "20px" }}>
                  <span className="blankwarningLogo">&#9888;</span>
                  <strong>{ProductTemplateConstants.phone}</strong>
                  {ProductTemplateConstants.phonenumber}
                </h3>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="EmptySpaceSmall3"></div>
            </div>
          </div>
        </div>
      </div>
      <LoadingOver open={openLogin} />
    </div>
  );
}
