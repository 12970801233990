import React from 'react';
import Button from '@material-ui/core/Button';
import '../styles/productButton.css'

export default function Hook(props) {
    return <Button className="Boton-main"
        onClick = {props.action}
        style = {props.style}
        ><h3 className="h3-stl-button">{props.label}</h3>
    </Button>;
}